const CollapsedList = () => {
	const block = document.querySelectorAll(".collapsed-list");
	if (!block) {
		return;
	}
	block.forEach((el) => {
		const line = el.querySelector(".collapsed-list__line");
    const title = el.querySelector(".collapsed-list__title span");

    if (line) {
      const svg = line.querySelector("svg");
      title.append(svg);
    }
	});
};

export default CollapsedList;
