const Intro = () => {
	const block = document.querySelectorAll(".intro");
	if (!block) {
		return;
	}
	block.forEach((el) => {
		const line = el.querySelector(".intro__head-line");
		const title = el.querySelector(".intro__title span");

		if (line) {
			title.append(line);
		}
	});
};

export default Intro;
