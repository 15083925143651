const AboutBox = () => {
	const block = document.querySelectorAll(".about-box");
	if (!block) {
		return;
	}
	block.forEach((el) => {
		const line = el.querySelector(".about-box__line");
    const title = el.querySelector(".about-box__title span");

    if (line) {
      const svg = line.querySelector("svg");
      title.append(svg);
    }
	});
};

export default AboutBox;
