import { gsap } from "gsap";

const TickerLogos = () => {
	const block = document.querySelectorAll(".ticker-logos");
	if (!block) {
		return;
	}
	block.forEach((el) => {
		const reverse = el.classList.contains("ticker-logos_reverse");
		const list = el.querySelector(".ticker-logos__list");
		const row = el.querySelector(".ticker-logos__row");
		const items = el.querySelectorAll(".ticker-logos__item");
		let inited = false;

		if (!inited) {
			list.append(row.cloneNode(true));
			inited = true;
		}

		gsap.to(list, {
			x: reverse ? row.offsetWidth : -row.offsetWidth,
			repeat: -1,
			duration: items.length * 5,
			ease: "none"
		})
	});
};

export default TickerLogos;
