import easyToggle from "easy-toggle-state";

import Intro from "./blocks/Intro";
import Footer from "./blocks/Footer";
import TickerLogos from "./blocks/TickerLogos";
import AboutBox from "./blocks/AboutBox";
import CollapsedList from "./blocks/CollapsedList";

const initScripts = () => {
	easyToggle();
};

initScripts();
Intro();
Footer();
TickerLogos();
AboutBox();
CollapsedList();
