const Footer = () => {
	const block = document.querySelector(".footer");
	if (!block) {
		return;
	}
  const year = block.querySelector(".footer__copyright-year");
	const date = new Date();

  year.textContent = date.getFullYear();
};

export default Footer;
